var app = new Vue({
    el: '#app',
    data: {
        tab: 'all_events',
        events: [],
        my_events: [],
        token: false,
        login: {
            email: '',
            password: ''
        },
        registration: {
            name: '',
            email: '',
            password: '',
            password_confirmation: ''
        },
        password_reset: {
            email: ''
        }
    },
    mounted: function() {
        if(window.location.pathname == '/meine-events') this.tab = 'my_events'
        if(localStorage.getItem('token') !== null) this.token = localStorage.getItem('token');
        /*if(localStorage.getItem('events') !== null) this.events = localStorage.getItem('events');
        if(localStorage.getItem('my_events') !== null) this.my_events = localStorage.getItem('my_events');*/
        this.reloadEvents();
        Vue.nextTick(function() {
            $('#header').css('background-image', 'url('+Laravel.url+'/assets/img/header.png)');

            /*$('#first_start').on('shown.bs.modal', function () {
                console.log('here');
                $('#wizard').bootstrapWizard({
                    tabClass: 'fw-nav',
                    'nextSelector': '.next',
                    'previousSelector': '.previous',
                    'finishSelector': '.last'
                });
            });
            $('#first_start').modal('show');*/
        });
    },
    methods: {
        openEvent: function(id) {
            window.location = Laravel.url+'/event/'+id;
        },
        reloadEvents: function() {
            var that = this;
            axios.get(Laravel.url+'/api/v1/events').then(function(res) {
                that.events = res.data;
            });
            if(this.token) {
                axios.get(Laravel.url+'/api/v1/my-events?api_token='+this.token).then(function(res) {
                    that.my_events = res.data;
                });
            }
        },      
        showLogin: function() {
            $('#login').modal('show');
        },
        showRegistration: function() {
            $('#login').modal('hide');
            $('#registration').modal('show');
        },
        showPasswordReset: function() {
            $('#login').modal('hide');
            $('#reset').modal('show');
        },
        attemptLogin: function() {
            var that = this;
            $('#login').modal('hide');
            axios.post(Laravel.url+'/api/v1/login', this.login).then(function(res) {
                if(res.data.success) {
                    that.token = res.data.user.api_token;
                    localStorage.setItem('token', that.token);
                    that.reloadEvents();
                    $.growl({ message: 'Erfolgreich eingeloggt.' },{ type: 'success' });
                    if(!(localStorage.noIntro != null ? localStorage.noIntro : false)) { 
                        localStorage.setItem('noIntro', true);
                        $('#first_start').on('shown.bs.modal', function () {
                            console.log('here');
                            $('#wizard').bootstrapWizard({
                                tabClass: 'fw-nav',
                                'nextSelector': '.next',
                                'previousSelector': '.previous',
                                'finishSelector': '.last'
                            });
                        });
                        $('#first_start').modal('show');
                    }
                } else {
                    $.growl({ message: 'Login fehlgeschlagen.' },{ type: 'danger' });
                }
            }).catch(function() {
                $.growl({ message: 'Login fehlgeschlagen.' },{ type: 'danger' });
            });
        },
        attemptRegistration: function() {
            var that = this;
            $('#registration').modal('hide');
            axios.post(Laravel.url+'/api/v1/register', this.login).then(function(res) {
                if(res.data.success) {
                    that.token = res.data.user.api_token;
                    localStorage.setItem('token', that.token);
                    that.reloadEvents();
                    $.growl({ message: 'Erfolgreich eingeloggt.' },{ type: 'success' });
                } else {
                    if(res.data.email) $.growl({ message: res.data.email },{ type: 'danger' });
                    if(res.data.password) $.growl({ message: res.data.password },{ type: 'danger' });
                    if(!res.data.password && !res.data.email) $.growl({ message: 'Registration fehlgeschlagen. Sind Sie bereits registriert?' },{ type: 'danger' });
                }
            }).catch(function(err) {
                console.log(err.response);
                if(err.response.data.email) $.growl({ message: err.response.data.email },{ type: 'danger' });
                if(err.response.data.password) $.growl({ message: err.response.data.password },{ type: 'danger' });
                if(!err.response.data.password && !err.responses.data.email) $.growl({ message: 'Verbindung zum Server konnte nicht hergestellt werden.' },{ type: 'danger' });
            });
        },
        attemptReset: function() {
            var that = this;
            $('#reset').modal('hide');
            axios.post(Laravel.url+'/api/v1/password/email', this.password_reset).then(function(res) {
                if(res.data.success) {
                    $.growl({ message: 'In Kürze erhalten Sie ein Email zum Zurücksetzen Ihres Passworts.' },{ type: 'success' });
                } else {
                    $.growl({ message: 'Passwort-Reset fehlgeschlagen! Haben Sie die richtige Email-Adresse angegeben?' },{ type: 'danger' });
                }
            }).catch(function() {
                $.growl({ message: 'Passwort-Reset fehlgeschlagen! Haben Sie die richtige Email-Adresse angegeben?' },{ type: 'danger' });
            });
        },
        logout: function() {
            localStorage.removeItem('token');
            localStorage.removeItem('my_events');
            localStorage.removeItem('downloads');
            this.token = false;
            this.my_events = [];
            this.downloads = [];
            $.growl({ message: 'Sie sind nun ausgeloggt..' },{ type: 'success' });
        },
        getStartDate: function(event) {
            var min = moment("9999-12-31 23:59:59");
            console.log(event);
            event.presentations.forEach(function(pres) {
                pres.dates.forEach(function(d) {
                    if(moment(d.date_from).valueOf() < min.valueOf()) min = moment(d.date_from);
                });
            });
            return min;
        },
        getEndDate: function(event) {
            var max = moment('1970-01-01');
            event.presentations.forEach(function(pres) {
                pres.dates.forEach(function(d) {
                    if(moment(d.date_to).valueOf() > max.valueOf()) max = moment(d.date_to);
                });
            });
            return max;
        },
        isOneDay: function(event) {
            return this.getStartDate(event).format('DD.MM.YYYY') == this.getEndDate(event).format('DD.MM.YYYY');
        },
        fileCount: function(event) {
            return event.event_files.length + event.presentations.map(function(p) { return p.files.length }).reduce(function(a, b) { return a + b }, 0)
        }
    },
    computed: {
        events_sorted: function() {
            var that = this;
            var evs = [];
            var e = this.events.forEach(function(ev) {
                //return moment().valueOf() < that.getEndDate(ev).valueOf();
                if(moment().valueOf() < that.getEndDate(ev).valueOf()) evs.push(ev);
            });
            evs.sort(function(a, b) {
                return that.getStartDate(a).valueOf() - that.getStartDate(b).valueOf();
            });
            return evs;
        },
        my_events_sorted: function() {
            var that = this;
            var e = [];
            this.my_events.forEach(function(obj) { e.push(obj.event); });
            e.sort(function(a, b) {
                return that.getStartDate(b).valueOf() - that.getStartDate(a).valueOf();
            });
            return e;
        }
    }
});

